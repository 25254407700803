<template>
    <div id="home-page">
        <PageHeader colour="white"
                    active-colour="yellow"
                    animate-logo
                    animate-logo-on-rollover
        />
        <ChristmasHeroSlide v-bind="hero"/>
        <!-- <PrimaryHeroSlide v-bind="hero"/> -->
        <CaseStudySlide v-for="(slide, index) in caseStudySlides"
                      :key="index"
                      v-bind="slide"
        ></CaseStudySlide>
        <ClientsSlide></ClientsSlide>
        <ContactSlide></ContactSlide>
    </div>
</template>

<script>
    import data from '../../../data';
    import ChristmasHeroSlide from '../../slides/ChristmasHeroSlide';
    // import PrimaryHeroSlide from '../../slides/PrimaryHeroSlide';
    import CaseStudySlide from '../../slides/CaseStudySlide';
    import ContactSlide from '../../slides/ContactSlide';
    import ClientsSlide from '../../slides/ClientsSlide';
    import PageHeader from "../partials/PageHeader";

    export default {
        components: {
            PageHeader,
            ChristmasHeroSlide,
            // PrimaryHeroSlide,
            CaseStudySlide,
            ContactSlide,
            ClientsSlide
        },

        data() {
            return Object.assign(
                {},
                data.home,
                {
                    caseStudies: data.caseStudies
                }
            );
        },

        computed: {
            caseStudySlides() {
                return this.caseStudies.filter(caseStudy => {
                    return ('preview' in caseStudy);
                }).sort((a, b) => {
                    console.log(a, b);

                    return a.preview.sort < b.preview.sort ? -1 : 1;
                }).map(caseStudy => {
                    let attributes = caseStudy.preview;

                    delete attributes.sort;

                    attributes.btn.to = {
                        name: 'case-study',
                        params: {
                            slug: caseStudy.lookupKey
                        }
                    };

                    return attributes;
                });
            }
        }
    }
</script>
