<template>
    <div class="christmas-hero-slide" ref="outerContainer">
        <baseBox padding="large py-0">
            <BContainerInset class="christmas-hero-slide__container"
                             ref="innerContainer"
            >


                <BaseBox class="christmas-hero-slide__text"
                         text-colour="white">
                    <h1 class="christmas-hero-slide__text__title"
                        v-html="title"
                    ></h1>
                    <p class="christmas-hero-slide__text__description"
                       v-html="description"
                    ></p>
                    <BaseBtn v-if="btn"
                             class="christmas-hero-slide__text__btn"
                             @click="routeTo"
                    >
                        {{ btn.text }}
                    </BaseBtn>
                </BaseBox>
            </BContainerInset>
        </baseBox>
        <ChristmasTree ref="christmasTree"
                        class="christmas-hero-slide__tree"
        ></ChristmasTree>
    </div>
</template>

<script>
    import ChristmasTree from '../views/partials/ChristmasTree';

    export default {
        components: {
            ChristmasTree
        },

        props: {
            title: String,
            description: String,
            btn: Object
        },

        methods: {
            routeTo() {
                if (this.btn && this.btn.route) {
                    this.$router.push({ name : this.btn.route })
                }
            }
        },

        mounted() {
            this.$refs.christmasTree.setAnchorContainer(this.$refs.innerContainer.$el);
            this.$refs.christmasTree.setHeightContainer(this.$refs.outerContainer);
        }
    }
</script>

<style lang="scss">
    .christmas-hero-slide {
        min-height: 100vh;
        position: relative;
        display: flex;
        flex-direction: column;
        background-color: map-get($palette, "purple");
        overflow: hidden;

        @include media-breakpoint-up(lg) {
            display: block;
        }

        h1 {
            color: map-get($palette, "yellow");
        }

        &__container {
            height: 100%;
            position: relative;

            .row {
                height: 100%;
            }
        }

        &__text {
            margin: 80px 0 50px 0px;

            @include media-breakpoint-up(md) {
                margin-top: 150px;
            }

            @include media-breakpoint-up(lg) {
                max-width: 360px;
                margin-top: 250px;
            }

            @include media-breakpoint-up(lg) {
                max-width: 410px;
            }

            @include media-breakpoint-up(xxl) {
                margin-top: 330px;
                margin-left: 12px;
            }

            &__title, &__description, &__btn {
                transform: translateX(-60px);
                opacity: 0;
            }

            &__title {
                animation: reveal-hero-text 400ms 1200ms forwards;
            }

            &__description {
                animation: reveal-hero-text 400ms 1300ms forwards;
                width: 270px;

                @include media-breakpoint-up(md) {
                    width: 365px;
                }

                @include media-breakpoint-up(lg) {
                    width: auto;
                }
            }

            &__btn {
                animation: reveal-hero-text 400ms 1400ms forwards;
                margin-top: 40px;
            }
        }

        &__tree {
            position: relative;
            background-color: map-get($palette, "teal-75");
            margin-top: -150px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            backface-visibility: hidden;
            pointer-events: none;

            @include media-breakpoint-up(md) {
                margin-top: -340px;
            }

            @include media-breakpoint-up(lg) {
                position: absolute;
                top: 115px;
                margin-top: 0;
            }

            @include media-breakpoint-up(xl) {
                top: 0;
            }
        }
    }

    @keyframes reveal-hero-text {
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }
</style>