<template>
    <BaseBox background-colour="yellow"
             class="secondary-hero-slide"
    >
        <div class="secondary-hero-slide__content">
            <div class="secondary-hero-slide__content__inner">
                <BaseBox padding="large py-0"
                         class="secondary-hero-slide__textbox"
                >
                    <BContainerInset class="secondary-hero-slide__container">
                        <BRow ref="container">
                            <BCol lg="3"
                                  xl="4"
                            >
                                <div class="secondary-hero-slide__text">
                                    <h1 class="secondary-hero-slide__text__title"
                                        v-html="title"
                                    ></h1>
                                    <p class="secondary-hero-slide__text__description"
                                       v-html="description"
                                    ></p>
                                </div>
                            </BCol>
                        </BRow>
                    </BContainerInset>
                </BaseBox>
                <div :style="assetStyles"
                     class="secondary-hero-slide__asset"
                >
                    <DeviceBrowserDesktop
                        v-if="showDevice"
                        colour="yellow"
                    >
                        <component v-if="assetComponent"
                                   :is="assetComponent"
                                   :src="asset.src"
                                   v-bind="additionalAssetProps"
                                   ref="asset"
                        />
                    </DeviceBrowserDesktop>
                    <component
                        v-else-if="assetComponent"
                        :is="assetComponent"
                        :src="asset.src"
                        v-bind="additionalAssetProps"
                        ref="asset"
                    />
                </div>
            </div>
        </div>
        <HeaderStripe ref="headerStripe"/>
    </BaseBox>
</template>

<script>
    import DeviceBrowserDesktop from '../devices/DeviceBrowserDesktop';
    import HeaderStripe from '../views/partials/stubs/HeaderStripe';

    export default {
        components: {
            DeviceBrowserDesktop,
            HeaderStripe
        },

        props: {
            asset: Object,
            title: String,
            description: String,
        },

        data() {
            return {
                windowWidth: window.innerWidth,
                anchorWidth: null
            }
        },

        computed: {
            gutterWidth() {
                return (this.windowWidth - this.anchorWidth) / 2;
            },

            assetStyles() {
                if (this.windowWidth > 991) {
                    let ratio = this.windowWidth > 1199 ? 3 : 2;
                    let width = this.gutterWidth + (this.anchorWidth / ratio);

                    return {
                        width: `${width}px`
                    };
                }

                return {};
            },

            assetComponent() {
                switch (this.asset.type) {
                    case 'image':
                        return 'BaseImg';

                    case 'video':
                        return 'BaseVideo';

                    default:
                        return null;
                }
            },

            additionalAssetProps() {
                if (this.assetComponent === 'BaseVideo') {
                    return {
                        autoplay: true
                    }
                }

                return {};
            },

            showDevice() {
                if (typeof this.asset === 'object' && this.asset.showDevice === false) {
                    return false;
                }

                return true;
            },
        },

        mounted() {
            this.windowWidth = document.body.clientWidth;
            this.anchorWidth = this.$refs.container.offsetWidth;

            this.$refs.headerStripe.setAnchorContainer(this.$refs.container);

            window.addEventListener('resize', () => {
                this.windowWidth = document.body.clientWidth;
                this.anchorWidth = this.$refs.container.offsetWidth;
            });
        }
    }
</script>

<style lang="scss">
    .secondary-hero-slide {
        position: relative;

        h1 {
            color: map-get($palette, "purple");
        }

        &__content {
            padding-top: 170px;

            @include media-breakpoint-up(lg) {
                padding-top: 200px;
            }

            &__inner {
                position: relative;
            }
        }

        &__textbox {
            margin-bottom: 40px;

            @include media-breakpoint-up(lg) {
                position: absolute;
                top: 15%;
                left: 0;
                right: 0;
                margin-bottom: 0;
            }

            @include media-breakpoint-up(xl) {
                top: 25%;
            }

            @include media-breakpoint-up(xxl) {
                top: 30%;
            }
        }

        &__text {
            &__title, &__description {
                transform: translateX(-60px);
                opacity: 0;
            }

            &__title {
                animation: reveal-hero-item 400ms 1200ms forwards;
            }

            &__description {
                animation: reveal-hero-item 400ms 1300ms forwards;
                margin-bottom: 0;
            }
        }

        &__asset {
            opacity: 0;
            transform: translateX(100px);
            animation: reveal-hero-item 400ms 1300ms forwards;
            margin-left: 40px;

            @include media-breakpoint-up(lg) {
                margin-left: auto;
            }

            .device-browser {
                display: flex;
                flex-direction: column;
                width: 100%;

                &__controls {
                    height: 35px;

                    @include media-breakpoint-up(sm) {
                        height: 50px;
                    }

                    @include media-breakpoint-up(md) {
                        height: auto;
                    }

                    svg {
                        height: 100%;

                        @include media-breakpoint-up(md) {
                            min-height: 100%;
                            min-width: 100%;
                        }
                    }
                }

                &__content {
                    flex-grow: 1;

                    > * {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: left;
                    }
                }
            }
        }
    }

    @keyframes reveal-hero-item {
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }
</style>
