<template>
    <div
        class="strip-stub"
    >
        <div
            v-for="(item, index) in resolvedItems"
            :key="index"
        >
            <component
                v-if="item.asset.component"
                :is="item.asset.component"
                :src="item.asset.src"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            required: true
        },
    },

    computed: {
        resolvedItems() {
            return this.items.map(item => {
                switch (item.asset.type) {
                    case 'image':
                        item.asset.component = 'BaseImg';
                        break;

                    case 'video':
                        item.asset.component = 'BaseVideo';
                        break;
                }

                return item;
            })
        },
    }
}
</script>

<style lang="scss">
    .strip-stub {
        display: grid;
        column-gap: 3%;
        grid-template-columns: repeat(6, minmax(0, 1fr));
        margin: 45px -85%;

        @include media-breakpoint-up(lg) {
            margin: 90px -45%;
            column-gap: 5%;
        }
    }
</style>
